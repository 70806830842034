import { Card, CardContent, CardHeader, Container, Grid } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";
import dayjs from "dayjs";
import React from "react";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import withAuth from "../../../../../Hoc/withAuth";
import ContentItem from "./Components/ContentItem";

const AgentResults = ({ user }) => {
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const evaluations = useQuery({
    queryKey: ["agentEvaluations", user.id],
    queryFn: () =>
      axios.get(
        `${process.env.REACT_APP_API_URL}/qa/evaluations/user/${user.id}`,
        config
      ),
  });

  const evaluationsData = evaluations.data?.data?.data;

  const evaluationsUpdatedToday = [];
  const evaluationsUpdatedThisWeek = [];
  const evaluationsUpdatedThisMonth = [];

  const today = dayjs().format("YYYY-MM-DD");
  const week = dayjs().subtract(7, "day").format("YYYY-MM-DD");
  const month = dayjs().subtract(30, "day").format("YYYY-MM-DD");

  evaluationsData?.map((evaluation) => {
    const date = dayjs(evaluation.updatedAt).format("YYYY-MM-DD");
    if (date === today) {
      evaluationsUpdatedToday.push(evaluation);
    }
    if (date > week) {
      evaluationsUpdatedThisWeek.push(evaluation);
    }
    if (date > month) {
      evaluationsUpdatedThisMonth.push(evaluation);
    }
    return null;
  });

  return (
    <Container>
      <PageHeader
        title="Agent Results"
        subheader="View Qa Agent results tracking"
      />
      <Card>
        <CardHeader title="Evaluations Updated Today" />
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <ContentItem
                title="Total Evaluations Updated Today"
                value={evaluationsUpdatedToday?.length}
              />
              <ContentItem
                title="Evaluations Completed Today"
                value={
                  evaluationsUpdatedToday?.filter(
                    (evaluation) => evaluation.stage === "Complete"
                  ).length || 0
                }
              />
            </Grid>
            <Grid item xs={6}>
              <ContentItem
                title="Evaluations Completed Today"
                value={
                  evaluationsUpdatedToday?.filter(
                    (evaluation) => evaluation.stage === "Fail"
                  ).length || 0
                }
              />
              <ContentItem
                title="Evaluations Completed Today"
                value={
                  evaluationsUpdatedToday?.filter(
                    (evaluation) => evaluation.stage === "Busy"
                  ).length || 0
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Evaluations Updated This Week" />
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <ContentItem
                title="Total Evaluations Updated This Week"
                value={evaluationsUpdatedThisWeek?.length}
              />
              <ContentItem
                title="Evaluations Completed this Week"
                value={
                  evaluationsUpdatedThisWeek?.filter(
                    (evaluation) => evaluation.stage === "Complete"
                  ).length || 0
                }
              />
            </Grid>
            <Grid item xs={6}>
              <ContentItem
                title="Evaluations Completed This Week"
                value={
                  evaluationsUpdatedThisWeek?.filter(
                    (evaluation) => evaluation.stage === "Fail"
                  ).length || 0
                }
              />
              <ContentItem
                title="Evaluations Completed This Week"
                value={
                  evaluationsUpdatedThisWeek?.filter(
                    (evaluation) => evaluation.stage === "Busy"
                  ).length || 0
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title="Evaluations Updated This Month" />
        <CardContent>
          <Grid container>
            <Grid item xs={6}>
              <ContentItem
                title="Total Evaluations Updated This Month"
                value={evaluationsUpdatedThisMonth?.length}
              />
              <ContentItem
                title="Evaluations Completed this Month"
                value={
                  evaluationsUpdatedThisMonth?.filter(
                    (evaluation) => evaluation.stage === "Complete"
                  ).length || 0
                }
              />
            </Grid>
            <Grid item xs={6}>
              <ContentItem
                title="Evaluations Completed This Month"
                value={
                  evaluationsUpdatedThisMonth?.filter(
                    (evaluation) => evaluation.stage === "Fail"
                  ).length || 0
                }
              />
              <ContentItem
                title="Evaluations Completed This Month"
                value={
                  evaluationsUpdatedThisMonth?.filter(
                    (evaluation) => evaluation.stage === "Busy"
                  ).length || 0
                }
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default withAuth(AgentResults);
