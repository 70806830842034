import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { Form, Formik } from "formik";
import withAuth from "../../../../../../Hoc/withAuth";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";
import { Alert, Tooltip } from "@mui/material";
import { StageActionDescription, StageColors } from "./StageColors";

// stage Options

//   "New",
//   "Complete",
//   "Fail",
//   "Cancelled",
//   "Busy",
//   "Re-evaluate",
//   "Re-evaluate Dispute",
//   "Dispute",
//   "Dispute Rejected",
//   "Dispute Cancelled",
//   "Dispute Re-evaluate",
//   "Completed" // Only for taskFlow/1Stream purposes

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StageChangeComponent = ({
  user,
  updateEval,
  stage_reason_history,
  toStage,
}) => {
  let history = stage_reason_history ? stage_reason_history : [];

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Tooltip title={StageActionDescription(toStage)}>
        <Button
          variant="contained"
          color={StageColors(toStage)}
          onClick={handleClickOpen}>
          {toStage} evaluation
        </Button>
      </Tooltip>
      <Dialog
        open={open}
        maxWidth="md"
        fullWidth
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle>{toStage} Evaluation</DialogTitle>
        <DialogContent sx={{ my: 4 }}>
          <Formik
            initialValues={{
              user: {
                user_id: user.id,
                name: user.name,
                surname: user.surname,
                email: user.email,
              },
              stage: toStage,
              date: new Date(),
              reason: "",
            }}
            onSubmit={(values) => {
              let data = {
                stage_reason_history: [values, ...history],
                stage: toStage,
              };
              updateEval.mutate(data);
              handleClose();
            }}>
            {() => {
              return (
                <Form>
                  <TextfieldWrapper
                    sx={{ my: 2 }}
                    name="reason"
                    label="Reason"
                    multiline
                    rows={4}
                  />
                  <DialogActions>
                    <Button onClick={handleClose}>cancel</Button>
                    <Button type="submit" color="secondary" variant="contained">
                      Submit
                    </Button>
                  </DialogActions>
                </Form>
              );
            }}
          </Formik>
        </DialogContent>
        {updateEval.isError && (
          <Alert>{updateEval.error.response.data.message}</Alert>
        )}
      </Dialog>
    </div>
  );
};

export default withAuth(StageChangeComponent);
