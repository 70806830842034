import {
  Accordion,
  AccordionSummary,
  Button,
  Card,
  CardContent,
  Chip,
  Grid,
  LinearProgress,
  List,
  ListSubheader,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import React, { useEffect, lazy, Suspense } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getEvaluationRecordings,
  getQaEvaluationById,
  getQaQueueTeams,
} from "./State/QaDuck";
import { getQaTeams } from "./State/QaTeamsDuck";
import SelectWrapper from "../../../../../Components/FormComponents/Select";
import TextfieldWrapper from "../../../../../Components/FormComponents/TextfieldWrapper";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import {
  RESET_LEAD_SALE_EVAL,
  getEvaluationResults,
} from "../QaAgent/State/AgentEvaluationsDuck";
import withAuth from "../../../../../Hoc/withAuth";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";

import EvaluationNotesList from "../QaAgent/Evaluations/EvaluationNotesList";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import UploadRecording from "../QaAgent/UploadRecording";
import MediaPlayerComp from "../../../../../Components/Bits/MediaPlayerComp";

import SetNewAgent from "./Components/Evaluations/SetNewAgent";
import { QuestionsAnswersList } from "../QaAgent/EvaluationResults";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import dayjs from "dayjs";

const ReEvaluateForm = lazy(() =>
  import("./Components/Evaluations/ReEvaluateForm"),
);

const Evaluation = ({ user }) => {
  const { id } = useParams();

  let params = useParams();

  const dispatch = useDispatch();
  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
    },
  };

  const updateEval = useMutation((values) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/qa/evaluationUpdate/${params.eval}`,
      values,
      config,
    );
  });

  useEffect(() => {
    dispatch({ type: RESET_LEAD_SALE_EVAL });
    dispatch(getQaTeams());
    dispatch(getQaEvaluationById(params.eval));
    dispatch(getEvaluationResults(params.eval));
    dispatch(getEvaluationRecordings(params.eval));
  }, [dispatch, id, params]);

  const Qa = useSelector((state) => state.Qa);
  const {
    CreateQEvalSuccess,
    qaQueueTeams,
    queueEvaluation,
    evaluationRecordings,
    recordingAdded,
  } = Qa;

  useEffect(() => {
    dispatch(getQaQueueTeams(queueEvaluation.queue_id));
  }, [queueEvaluation, dispatch]);

  const [totalCallTime, setTotalCallTime] = React.useState(0);

  const { AgentEvaluations } = useSelector((state) => state.QAAgent);
  const { evaluationsResults } = AgentEvaluations;

  const QAEvals = useSelector((state) => state.QAEvals);
  const { savedResults } = QAEvals;

  const [reEvaluated, setReEvaluated] = React.useState(false);

  // Get Dispositions out of list

  // console.log("eval", evaluationsResults);

  return (
    <div>
      <PageHeader
        title="Evaluation"
        subheader="Create Evaluation for a Lead"
        breadcrumbs={[
          {
            title: "Dashboard",
            href: "/",
          },
        ]}
      />

      <AlertPopup
        open={CreateQEvalSuccess}
        message="Evaluation Created Successfully"
        severity="success"
      />
      <AlertPopup
        open={savedResults}
        message="Review Submitted successfully"
        severity="success"
      />
      <AlertPopup
        open={updateEval.isSuccess}
        message="Updated Successfully"
        severity="success"
      />

      <Grid container spacing={2}>
        <Grid item xs={12}>
          {evaluationsResults && (
            <QuestionsAnswersList Evaluation={evaluationsResults} />
          )}
        </Grid>
        <Grid item xs={12}>
          <Formik
            initialValues={{
              queue_id: evaluationsResults?.Evaluation?.queue_id,
              stage: evaluationsResults?.Evaluation?.stage || "",
              user_id: evaluationsResults?.Evaluation?.user_id || "",
              start_date: evaluationsResults?.Evaluation?.start_date || "",
              end_date: evaluationsResults?.Evaluation?.end_date || "",
              stage_reason_history:
                evaluationsResults?.Evaluation?.stage_reason_history || [],
              note1: evaluationsResults?.Evaluation?.note1 || "",
              note2: evaluationsResults?.Evaluation?.note2 || "",
              note3: evaluationsResults?.Evaluation?.note3 || "",
              note4: evaluationsResults?.Evaluation?.note4 || "",
              note5: evaluationsResults?.Evaluation?.note5 || "",
              updated_reason: "Manually Edited Evaluation",
              history: evaluationsResults?.Evaluation?.history || [],
            }}
            enableReinitialize={true}
            // validationSchema={Yup.object({
            //   uni_id: Yup.string().required("Required"),
            //   disposition_id: Yup.string().required("Required"),
            //   queue_id: Yup.string().required("Required"),
            //   user_id: Yup.string().required("Required"),
            //   agent_id: Yup.string().required("Required"),
            //   stage: Yup.string().required("Required"),
            // })}
            onSubmit={(values) => {
              updateEval.mutate(values);

              // setTimeout(
              //   () => navigate(`/CDA/QaQueuesSetup/${id}/Evaluations`),
              //   2000
              // );
            }}
          >
            {({ values, setFieldValue }) => {
              return (
                <Form>
                  <Card>
                    <List
                      dense
                      subheader={<ListSubheader>Evaluation</ListSubheader>}
                    ></List>
                    <CardContent>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Typography variant="h6">
                            Unique Identifier :{" "}
                            {evaluationsResults?.Evaluation?.uni_id}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          {evaluationsResults?.Evaluation?.stage ===
                          "Complete" ? (
                            <>
                              Stage:{" "}
                              <Chip
                                label="Evaluation Complete"
                                color="success"
                              />
                            </>
                          ) : (
                            <SelectWrapper
                              name="stage"
                              label="stage"
                              fullWidth
                              variant="outlined"
                              options={[
                                { value: "New", label: "New" },
                                { value: "Busy", label: "Busy" },
                                { value: "Re-evaluate", label: "Re-evaluate" },
                                { value: "Complete", label: "Complete" },
                                { value: "Fail", label: "Fail" },
                              ]}
                            />
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>Start Date</Typography>
                          {values.start_date &&
                            dayjs(values.start_date).format(
                              "DD-MMM-YYYY:HH:mm:ss",
                            )}
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>End Date</Typography>
                          {values.end_date &&
                            dayjs(values.end_date).format(
                              "DD-MMM-YYYY:HH:mm:ss",
                            )}
                        </Grid>
                        {values.history.length > 0 && (
                          <Grid item xs={12}>
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1-content"
                                id="panel1-header"
                              >
                                Stage History
                              </AccordionSummary>

                              {
                                //Updated with details LOURENS 20240717
                                /* {values.stage_reason_history?.map((reason) => {
                              return (
                                <Card>
                                  <CardContent>
                                    <Chip
                                      label={reason.stage}
                                      color={
                                        reason.stage === "Complete"
                                          ? "secondary"
                                          : reason.stage === "Busy"
                                          ? "warning"
                                          : reason.stage ===
                                            ("New" || "Re-evaluate")
                                          ? "primary"
                                          : reason.stage === "Fail"
                                          ? "error"
                                          : "info"
                                      }
                                    />
                                    <Typography variant="h6">
                                      {reason.date &&
                                        dayjs(reason.date).format(
                                          "DD-MMM-YYYY:HH:mm:ss"
                                        )}
                                    </Typography>

                                    <Typography>
                                      {reason?.user?.name}{" "}
                                      {reason?.user?.surname}
                                    </Typography>
                                  </CardContent>
                                </Card>
                              );
                            })} */
                              }
                              {values.history?.map((reason) => {
                                return (
                                  <Card>
                                    <CardContent>
                                      <Chip
                                        label={reason.previous_status}
                                        color={
                                          reason.previous_status === "Complete"
                                            ? "secondary"
                                            : reason.previous_status === "Busy"
                                            ? "warning"
                                            : reason.previous_status ===
                                              ("New" || "Re-evaluate")
                                            ? "primary"
                                            : reason.previous_status === "Fail"
                                            ? "error"
                                            : "info"
                                        }
                                      />

                                      <Typography>
                                        {`Start: ${dayjs(
                                          reason.session_start,
                                        ).format("DD-MMM-YYYY:HH:mm:ss")}`}
                                      </Typography>

                                      {reason.session_end && (
                                        <>
                                          <Typography>
                                            {`End: ${dayjs(
                                              reason.session_end,
                                            ).format("DD-MMM-YYYY:HH:mm:ss")}`}
                                          </Typography>
                                          <Typography>
                                            {`Minutes: ${(
                                              reason.evaluation_time * 60
                                            ).toFixed(2)}`}
                                          </Typography>
                                        </>
                                      )}

                                      <Typography>{`Agent: ${reason?.qa_agent}`}</Typography>
                                    </CardContent>
                                  </Card>
                                );
                              })}
                            </Accordion>
                          </Grid>
                        )}
                        <Grid item xs={6}>
                          <TextfieldWrapper
                            name="note1"
                            label="Note 1"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextfieldWrapper
                            name="note2"
                            label="Note 2"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextfieldWrapper
                            name="note3"
                            label="Note 3"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextfieldWrapper
                            name="note4"
                            label="Note 4"
                            fullWidth
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant="h6">QA Agent</Typography>
                          <SetNewAgent
                            disabled={
                              evaluationsResults?.Evaluation?.stage ===
                              "Complete"
                            }
                            user={values.user_id}
                            setUser={setFieldValue}
                            teams={qaQueueTeams}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                  </Card>
                  <Stack sx={{ my: 2 }}>
                    <Button fullWidth variant="contained" type="submit">
                      Save
                    </Button>
                  </Stack>
                </Form>
              );
            }}
          </Formik>
        </Grid>

        <Grid item xs={6}>
          <Card>
            <CardContent>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Evaluation Recordings
              </Typography>
              <UploadRecording
                id={params.eval}
                recordingAdded={recordingAdded}
              />
              <Grid container spacing={2} sx={{ mt: 2 }}>
                {evaluationRecordings?.map((recording, index) => {
                  return (
                    <Grid item xs={6} key={index}>
                      <Stack>
                        <MediaPlayerComp
                          totalCallTime={totalCallTime}
                          setTotalCallTime={setTotalCallTime}
                          src={`${process.env.REACT_APP_API_URL}/qa/evaluations/${recording.id}/recording/${recording.storage_id}`}
                        />

                        <Typography variant="caption" component="div">
                          {recording?.name}
                        </Typography>
                      </Stack>
                    </Grid>
                  );
                })}
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={6}>
          <EvaluationNotesList evaluation_id={params.eval} user={user} />
        </Grid>
        {/* 
        {queueEvaluation?.stage === "Dispute" && (
          <Grid item xs={12}>
            <StageChangeComponent
              updateEval={updateEval}
              stage_reason_history={evaluationsResults?.stage_reason_history}
              toStage="Dispute Rejected"
              label="Reject Dispute"
            />
          </Grid>
        )} */}

        {/* {queueEvaluation?.callbi_result && (
          <Grid item xs={12}>
            <CallbiResults callbi_result={evaluationsResults?.callbi_result} />
          </Grid>
        )} */}
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          {/* <QuestionsAnswersList Evaluation={evaluationsResults} /> */}
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12}>
          {/* {queueEvaluation.qa_evaluation_progresses &&
            queueEvaluation.qa_evaluation_progresses.length > 0 && (
              <EvaluationHistory
                progress={queueEvaluation.qa_evaluation_progresses}
              />
            )} */}
        </Grid>
        <Grid item xs={12} sx={{ my: 4 }}>
          {!reEvaluated ? (
            <Stack spacing={3}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setReEvaluated(!reEvaluated);
                }}
              >
                Admin Evaluate
              </Button>
              {/* <QaHistoryTable questions={evaluationsResults?.questions} /> */}
            </Stack>
          ) : (
            <Button
              variant="contained"
              color="warning"
              onClick={() => {
                setReEvaluated(!reEvaluated);
              }}
            >
              Cancel
            </Button>
          )}
        </Grid>
        {reEvaluated && (
          <Grid item xs={12}>
            <Suspense fallback={<LinearProgress />}>
              <ReEvaluateForm
                questions={evaluationsResults?.questions}
                Evaluation={evaluationsResults?.Evaluation}
              />
            </Suspense>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default withAuth(Evaluation);
