import DisplayLeadDetails from "../CallCenterAgents/components/DisplayLeadDetails";
import MediaPlayerComp from "../../../../../Components/Bits/MediaPlayerComp";
// import EvaluationQuestions from "./Evaluations/EvaluationQuestions";
import PageHeader from "../../../../../Components/Bits/PageHeader";
import AlertPopup from "../../../../../Components/Bits/AlertPopup";
import EvaluationHistory from "./Components/EvaluationHistory";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useDispatch, useSelector } from "react-redux";
import ContentItem from "./Components/ContentItem";
import UploadRecording from "./UploadRecording";
import { useNavigate } from "react-router-dom";
import SaleCard from "./Evaluations/SaleCard";
import { useParams } from "react-router-dom";
import {
  getEvaluationRecordings,
  getQaEvaluationById,
} from "../QAManagement/State/QaDuck";
import {
  getEvalSaleByUniId,
  getEvaluationResults,
  RESET_LEAD_SALE_EVAL,
  RESET_SAVE,
  StartEvaluationRequest,
} from "./State/AgentEvaluationsDuck";
import { useEffect } from "react";
import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Card,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import withAuth from "../../../../../Hoc/withAuth";
import EvalQuestionList from "./Evaluations/EvalQuestionList";
import EvaluationNotesList from "./Evaluations/EvaluationNotesList";
import StageChangeComponent from "./Components/StageChangeComponent";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { StageColors } from "./Components/StageColors";
import EvaluationStageHistory from "./Components/EvaluationStageHistory";
import { CreateNotification } from "../../../../../Redux/Ducks/NotificationDuck";
import CallbiResults from "./Components/CallbiResults";
import { QuestionsAnswersList } from "./EvaluationResults";

const StartEvaluation = ({ user }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id, uni_id } = useParams();
  const [startEval, setStartEval] = React.useState(false);
  const [totalCallTime, setTotalCallTime] = React.useState(0);

  useEffect(() => {
    dispatch({ type: RESET_LEAD_SALE_EVAL });
    dispatch(getEvalSaleByUniId(uni_id));
    dispatch(getQaEvaluationById(id));
    dispatch(getEvaluationRecordings(id));
    dispatch(getEvaluationResults(id));
  }, [dispatch, uni_id, id]);

  const { AgentEvaluations } = useSelector((state) => state.QAAgent);

  const {
    leadAndSale,
    Evaluation,
    saveSuccess,
    error,
    EvaluationsFinished,
    Loading,
    evaluationsResults,
  } = AgentEvaluations;

  const Qa = useSelector((state) => state.Qa);
  const { queueEvaluation, evaluationRecordings, recordingAdded } = Qa;

  const HandleStart = () => {
    setStartEval(true);
    dispatch(
      StartEvaluationRequest(id, {
        totalCallTime: totalCallTime,
      })
    );
  };

  const handleClose = () => {
    dispatch({ type: RESET_SAVE });
  };

  const config = {
    headers: {
      Authorization: `Bearer ${user.token}`,
      ContentType: "application/json",
    },
  };

  const updateEval = useMutation(
    (values) => {
      return axios.post(
        `${process.env.REACT_APP_API_URL}/qa/evaluationUpdate/${id}`,
        values,
        config
      );
    },
    {
      onSuccess: (data) => {
        let notification = {
          title: `Evaluation Updated ${data.data.data.uni_id}`,
          message: `Evaluation Stage Updated to ${data.data.data.stage}`,
          type: "info",
          user_id: data.data.data.agent_id,
          link: `/CDA/StartEvaluation/${data.data.data.id}/${data.data.data.uni_id}`,
        };
        dispatch(CreateNotification(notification));
      },
    }
  );

  const HandleEvaluationFinished = () => {
    setTimeout(() => {
      navigate(`/CDA/EvaluationResults/${queueEvaluation?.id}/${uni_id}`);
    }, 500);
  };

  if (Loading) {
    <Alert severity="info">Loading...</Alert>;
  }

  return (
    <>
      <AlertPopup
        open={updateEval.isSuccess}
        severity="success"
        message="Evaluation Updated"
      />
      <AlertPopup
        open={updateEval.isError}
        severity="error"
        message="An Error Occurred"
      />
      <AlertPopup
        open={recordingAdded}
        severity="success"
        message="Recording Added"
      />
      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={saveSuccess}
          autoHideDuration={6000}
          onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            sx={{ width: "100%" }}>
            Save SuccessFull
          </Alert>
        </Snackbar>
      </Stack>

      <Stack spacing={2} sx={{ width: "100%" }}>
        <Snackbar
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          open={EvaluationsFinished}
          autoHideDuration={6000}
          onClose={HandleEvaluationFinished}>
          <Alert
            onClose={HandleEvaluationFinished}
            severity="success"
            sx={{ width: "100%" }}>
            Evaluation Finished. Redirecting
          </Alert>
        </Snackbar>
      </Stack>

      <AlertPopup open={error} severity="error" message="An Error Occurred" />
      <PageHeader
        title="Start Evaluation"
        subheader="Start Evaluation"
        breadcrumbs={[
          { title: "Home", href: "/" },
          { title: "Qa Agent Home", href: "/CDA/QaAgentHome" },
          {
            title: "Start Evaluation",
            href: "/CDA/QaAgentHome/StartEvaluation",
          },
        ]}
      />

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>View Results</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {evaluationsResults && (
                <QuestionsAnswersList Evaluation={evaluationsResults} />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={4}>
          <SaleCard sale={leadAndSale} />
        </Grid>

        <Grid item xs={8}>
          <Card>
            <List
              dense
              subheader={<ListSubheader>Evaluation Details</ListSubheader>}>
              <Divider />
              <ListItem
                secondaryAction={
                  <Chip
                    color={StageColors(queueEvaluation?.stage)}
                    label={queueEvaluation.stage}
                  />
                }>
                <ListItemText
                  primary={
                    <>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body2"
                        color="text.secondary">
                        Reference Number
                      </Typography>
                    </>
                  }
                  secondary={
                    <>
                      <Typography
                        sx={{ display: "inline" }}
                        component="span"
                        variant="body1"
                        color="text.primary">
                        {queueEvaluation?.uni_id}
                      </Typography>
                    </>
                  }
                />
              </ListItem>
              <Divider />
            </List>

            <ContentItem title="note1" value={queueEvaluation?.note1} />
            <ContentItem title="note2" value={queueEvaluation?.note2} />
          </Card>
          <Typography variant="h6" sx={{ mt: 2 }}>
            Evaluation Recordings
          </Typography>
          <UploadRecording id={id} recordingAdded={recordingAdded} />
          <Grid container spacing={2} sx={{ mt: 2 }}>
            {evaluationRecordings?.map((recording, index) => {
              return (
                <Grid item xs={6} key={index}>
                  <Stack>
                    <MediaPlayerComp
                      totalCallTime={totalCallTime}
                      setTotalCallTime={setTotalCallTime}
                      src={`${process.env.REACT_APP_API_URL}/qa/evaluations/${recording.id}/recording/${recording.storage_id}`}
                    />

                    <Typography variant="caption" component="div">
                      {recording?.name}
                    </Typography>
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
          <Typography variant="h6" align="center">
            Total Call Time : ~ {(totalCallTime / 60).toFixed()} Minutes
          </Typography>
        </Grid>

        {queueEvaluation?.stage === "Dispute" && (
          <Grid item xs={12}>
            <StageChangeComponent
              updateEval={updateEval}
              stage_reason_history={queueEvaluation?.stage_reason_history}
              toStage="Dispute Rejected"
              label="Reject Dispute"
            />
          </Grid>
        )}

        {queueEvaluation?.callbi_result && (
          <Grid item xs={12}>
            <CallbiResults callbi_result={queueEvaluation?.callbi_result} />
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h6">Stage History</Typography>

          <EvaluationStageHistory
            stage_reason_history={queueEvaluation?.stage_reason_history}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography variant="h6">General Notes</Typography>
          <EvaluationNotesList evaluation_id={id} />
        </Grid>

        <Grid item xs={12}>
          <Accordion sx={{ mt: 2 }}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header">
              <Typography>View Lead Details</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {leadAndSale?.cm_lead && (
                <DisplayLeadDetails lead={leadAndSale?.cm_lead} />
              )}
            </AccordionDetails>
          </Accordion>

          {queueEvaluation.qa_evaluation_progresses &&
            queueEvaluation.qa_evaluation_progresses.length > 0 && (
              <EvaluationHistory
                progress={queueEvaluation.qa_evaluation_progresses}
              />
            )}
        </Grid>
      </Grid>

      {queueEvaluation && queueEvaluation.stage !== "Complete" ? (
        <>
          {startEval ? (
            // <>{Evaluation && <EvaluationQuestions Evaluation={Evaluation} />}</>
            <>
              {Evaluation && (
                <EvalQuestionList
                  questions={evaluationsResults?.questions}
                  Evaluation={evaluationsResults.Evaluation}
                />
              )}
            </>
          ) : (
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 4 }}
              onClick={HandleStart}>
              {queueEvaluation.stage === "New"
                ? " Start Evaluation"
                : queueEvaluation.stage === "Fail"
                ? "Redo Evaluation"
                : queueEvaluation.stage === "Busy"
                ? "Continue Evaluation"
                : "Evaluation Finished"}
            </Button>
          )}
        </>
      ) : (
        <Alert
          sx={{ mt: 4 }}
          severity="info"
          action={
            <>
              <Button
                color="inherit"
                size="small"
                onClick={HandleEvaluationFinished}>
                View Results
              </Button>
            </>
          }>
          Evaluation Already Completed
        </Alert>
      )}
    </>
  );
};

export default withAuth(StartEvaluation);
