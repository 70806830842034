import axios from "axios";

const initialState = {
  evaluations: [],
  Evaluation: {},
  leadAndSale: {},
  answers: [],
  leadAndSaleSuccess: false,
  leadAndSaleError: null,
  loading: false,
  error: null,
  saveSuccess: false,
  EvaluationsFinished: false,
  evaluationResults: {},
};

const GET_EVALUATIONS_REQUEST = "GET_EVALUATIONS_REQUEST";
const GET_EVALUATIONS_SUCCESS = "GET_EVALUATIONS_SUCCESS";
const GET_EVALUATIONS_FAILURE = "GET_EVALUATIONS_FAILURE";

const START_EVALUATION_REQUEST = "START_EVALUATION_REQUEST";
const START_EVALUATION_SUCCESS = "START_EVALUATION_SUCCESS";
const START_EVALUATION_FAILURE = "START_EVALUATION_FAILURE";

const EVAL_SALE_BY_UNI_ID_REQUEST = "EVAL_SALE_BY_UNI_ID_REQUEST";
const EVAL_SALE_BY_UNI_ID_SUCCESS = "EVAL_SALE_BY_UNI_ID_SUCCESS";
const EVAL_SALE_BY_UNI_ID_FAILURE = "EVAL_SALE_BY_UNI_ID_FAILURE";

const SAVE_EVALUATION_REQUEST = "SAVE_EVALUATION_REQUEST";
const SAVE_EVALUATION_SUCCESS = "SAVE_EVALUATION_SUCCESS";
const SAVE_EVALUATION_FAILURE = "SAVE_EVALUATION_FAILURE";

const GET_EVALUATIONS_ANSWERS = "GET_EVALUATIONS_ANSWERS";
const GET_EVALUATIONS_ANSWERS_SUCCESS = "GET_EVALUATIONS_ANSWERS_SUCCESS";
const GET_EVALUATIONS_ANSWERS_FAILURE = "GET_EVALUATIONS_ANSWERS_FAILURE";

const FINISH_EVALUATION_REQUEST = "FINISH_EVALUATION_REQUEST";
const FINISH_EVALUATION_SUCCESS = "FINISH_EVALUATION_SUCCESS";
const FINISH_EVALUATION_FAILURE = "FINISH_EVALUATION_FAILURE";

const GET_EVALUATIONS_RESULTS_REQUEST = "GET_EVALUATIONS_RESULTS_REQUEST";
const GET_EVALUATIONS_RESULTS_SUCCESS = "GET_EVALUATIONS_RESULTS_SUCCESS";
const GET_EVALUATIONS_RESULTS_FAILURE = "GET_EVALUATIONS_RESULTS_FAILURE";

export const RESET_LEAD_SALE_EVAL = "RESET_LEAD_SALE_EVAL";

export const RESET_SAVE = "RESET_SAVE";

const AgentEvaluationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case EVAL_SALE_BY_UNI_ID_REQUEST:
      return {
        ...state,
        loading: true,
        leadAndSaleSuccess: false,
        error: null,
        EvaluationsFinished: false,
        leadAndSaleError: null,
      };
    case EVAL_SALE_BY_UNI_ID_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        leadAndSaleSuccess: true,
        leadAndSale: action.payload,
      };
    case EVAL_SALE_BY_UNI_ID_FAILURE:
      return {
        ...state,
        loading: false,
        leadAndSaleError: action.payload,
        leadAndSaleSuccess: false,
      };

    case GET_EVALUATIONS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_EVALUATIONS_SUCCESS:
      return {
        ...state,
        evaluations: action.payload,
        loading: false,
        error: null,
      };
    case GET_EVALUATIONS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case START_EVALUATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case START_EVALUATION_SUCCESS:
      return {
        ...state,
        Evaluation: action.payload,
        loading: false,
        error: null,
      };
    case START_EVALUATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case SAVE_EVALUATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SAVE_EVALUATION_SUCCESS:
      return {
        ...state,

        saveSuccess: true,
        loading: false,
        error: null,
      };
    case SAVE_EVALUATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case RESET_SAVE:
      return {
        ...state,
        saveSuccess: false,
      };

    case GET_EVALUATIONS_ANSWERS:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_EVALUATIONS_ANSWERS_SUCCESS:
      return {
        ...state,
        answers: action.payload,

        loading: false,
        error: null,
      };
    case GET_EVALUATIONS_ANSWERS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case FINISH_EVALUATION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FINISH_EVALUATION_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        EvaluationsFinished: true,
      };
    case FINISH_EVALUATION_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case RESET_LEAD_SALE_EVAL:
      return {
        ...state,
        EvaluationsFinished: false,
        leadAndSale: {},
        leadAndSaleSuccess: false,
        leadAndSaleError: null,
        Evaluation: {},
      };

    case GET_EVALUATIONS_RESULTS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_EVALUATIONS_RESULTS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        evaluationsResults: action.payload,
      };
    case GET_EVALUATIONS_RESULTS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export default AgentEvaluationsReducer;

export const getAgentEvaluations =
  ({ id }) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_EVALUATIONS_REQUEST });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/qa/evaluations/user/${id}`,
        config
      );

      dispatch({
        type: GET_EVALUATIONS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_EVALUATIONS_FAILURE,
        payload: err.response,
      });
    }
  };

export const EvaluationsStart =
  ({ id }) =>
  async (dispatch, getState) => {
    dispatch({ type: GET_EVALUATIONS_REQUEST });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/qa/evaluations/start/${id}`,
        config
      );

      dispatch({
        type: GET_EVALUATIONS_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: GET_EVALUATIONS_FAILURE,
        payload: err.response,
      });
    }
  };

export const getEvalSaleByUniId = (id) => async (dispatch, getState) => {
  dispatch({ type: EVAL_SALE_BY_UNI_ID_REQUEST });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/sale/${id}`,
      config
    );

    dispatch({
      type: EVAL_SALE_BY_UNI_ID_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: EVAL_SALE_BY_UNI_ID_FAILURE,
      payload: err.response,
    });
  }
};

export const StartEvaluationRequest =
  (id, body) => async (dispatch, getState) => {
    dispatch({ type: START_EVALUATION_REQUEST });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/qa/users/start/${id}`,
        body,
        config
      );

      dispatch({
        type: START_EVALUATION_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: START_EVALUATION_FAILURE,
        payload: err.response,
      });
    }
  };

export const saveEvaluation =
  ({ answers, current_session }) =>
  async (dispatch, getState) => {
    dispatch({ type: SAVE_EVALUATION_REQUEST });
    try {
      const { AuthDuck } = getState();

      const config = {
        headers: {
          Authorization: `Bearer ${AuthDuck.userInfo.token}`,
        },
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/qa/evaluations/answers`,
        {
          answers,
          current_session,
        },
        config
      );

      dispatch({
        type: SAVE_EVALUATION_SUCCESS,
        payload: data.data,
      });
    } catch (err) {
      dispatch({
        type: SAVE_EVALUATION_FAILURE,
        payload: err.response,
      });
    }
  };

export const getEvaluationAnswers = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_EVALUATIONS_ANSWERS });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/evaluations/${id}/results`,
      config
    );

    dispatch({
      type: GET_EVALUATIONS_ANSWERS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_EVALUATIONS_ANSWERS_FAILURE,
      payload: err.response,
    });
  }
};

export const finishEvaluation = (id, values) => async (dispatch, getState) => {
  dispatch({ type: FINISH_EVALUATION_REQUEST });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.patch(
      `${process.env.REACT_APP_API_URL}/qa/users/finish/${id}`,
      values,
      config
    );

    dispatch({
      type: FINISH_EVALUATION_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: FINISH_EVALUATION_FAILURE,
      payload: err.response,
    });
  }
};

export const getEvaluationResults = (id) => async (dispatch, getState) => {
  dispatch({ type: GET_EVALUATIONS_RESULTS_REQUEST });
  try {
    const { AuthDuck } = getState();

    const config = {
      headers: {
        Authorization: `Bearer ${AuthDuck.userInfo.token}`,
      },
    };

    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/qa/evaluations/${id}/results`,
      config
    );

    dispatch({
      type: GET_EVALUATIONS_RESULTS_SUCCESS,
      payload: data.data,
    });
  } catch (err) {
    dispatch({
      type: GET_EVALUATIONS_RESULTS_FAILURE,
      payload: err.response,
    });
  }
};
