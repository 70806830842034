import { Alert, Box, LinearProgress } from "@mui/material";
import { Stack } from "@mui/system";
import { DataGridPremium } from "@mui/x-data-grid-premium";
import { GridToolbar } from "@mui/x-data-grid-pro";
import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import React from "react";
import withAuth from "../../../../../../Hoc/withAuth";

const SalesVsQaReports = ({ user }) => {
  const { token } = user;

  const { data, isLoading, isRefetching } = useQuery(
    ["GetQaStats"],
    async () => {
      return axios.get(
        `${process.env.REACT_APP_API_URL}/reports/QaReports/SalesVsQAStageReport`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
    },
  );

  // add id key to each object in the array
  let campaigns = data?.data?.data.map((item, index) => {
    console.log(item);
    console.log(index);
    return { ...item, id: index + 1 };
  });

  const columns = [
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      flex: 1,
      minWidth: 350,
    },
    {
      field: "sales",
      headerName: "Total Sales",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "not_loaded",
      headerName: "Not Loaded",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "incomplete",
      headerName: "Incomplete",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "re_evaluate",
      headerName: "Re-Evaluate",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "complete",
      headerName: "Complete",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "completePercentage",
      headerName: "% Completed",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "fail",
      headerName: "Failed",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "dispute",
      headerName: "Dispute",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "dispute_rejected",
      headerName: "Dispute - Rejected",
      flex: 1,
      minWidth: 100,
    },
    // {
    //   field: "notLoadedPercentage",
    //   headerName: "% Not Loaded",
    //   flex: 1,
    //   minWidth: 100,
    // },
    // {
    //   field: "incompletePercentage",
    //   headerName: "% Incomplete",
    //   flex: 1,
    //   minWidth: 100,
    // },

    // {
    //   field: "failPercentage",
    //   headerName: "% Failed",
    //   flex: 1,
    //   minWidth: 100,
    // },
    // {
    //   field: "reEvaluatePercentage",
    //   headerName: "% Re-Evaluate",
    //   flex: 1,
    //   minWidth: 100,
    // },
  ];

  return (
    <Stack sx={{ mt: 3 }}>
      {(isRefetching || isLoading) && <LinearProgress />}

      <Box sx={{ height: "60vh", mt: 4, width: "80vw" }}>
        {campaigns && campaigns.length > 0 ? (
          <DataGridPremium
            rows={campaigns}
            columns={columns}
            loading={isLoading}
            components={{
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: {
                sx: {
                  backgroundColor: "background.paper",
                },
              },
            }}
          />
        ) : (
          <Alert severity="info">No Data Found</Alert>
        )}
      </Box>
    </Stack>
  );
};

export default withAuth(SalesVsQaReports);
