import * as React from "react";
import Button from "@mui/material/Button";

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Chip, Stack } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";

import withAuth from "../../../../../../Hoc/withAuth";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import TextfieldWrapper from "../../../../../../Components/FormComponents/TextfieldWrapper";

const ReinstateEvaluation = ({ user, evaluation }) => {
  const [open, setOpen] = React.useState(false);

  const navigate = useNavigate();

  const reinstate = useMutation([`reinstate`], (data) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/qa/queues/${evaluation?.queue_id}/evaluations`,
      data,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
  });

  const updateEval = useMutation([`evaluation${evaluation?.id}`], (data) => {
    return axios.post(
      `${process.env.REACT_APP_API_URL}/qa/evaluationUpdate/${evaluation?.id}`,
      data,
      {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      }
    );
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (values) => {
    const data0 = {
      ...evaluation,
      stage_reason_history: [
        {
          date: new Date(),
          user: {
            id: user.id,
            name: user.name,
            email: user.email,
            surname: user.surname,
          },
          stage: "Reinstated",
          reason: "Agent Requested Reinstate",
        },
      ],
    };

    updateEval.mutate(data0);

    const data = {
      ...evaluation,
      stage: "new",
      stage_reason_history: [
        {
          date: new Date(),
          user: {
            id: user.id,
            name: user.name,
            email: user.email,
            surname: user.surname,
          },
          stage: "Reinstated",
          reason: values.reason,
        },
      ],
    };

    delete data?.id;
    delete data?.created_at;
    delete data?.updated_at;
    delete data?.deleted_at;
    delete data?.callbi_result;
    delete data?.stage_reason_history;
    delete data?.expireDate;

    reinstate.mutate(data, {
      onSuccess: (data) => {
        console.log("data", data);
        navigate(`/CDA/QaAgentHome/${data.data.data.id}`);
      },
    });
  };

  return (
    <div>
      <div>
        {evaluation?.stage_reason_history &&
        evaluation?.stage_reason_history.length > 0 &&
        evaluation?.stage_reason_history[0]?.stage === "Reinstated" ? (
          <Chip variant="success" label="Evaluation Reinstated" />
        ) : (
          <Button variant="contained" color="primary" onClick={handleClickOpen}>
            Re-evaluate Evaluation
          </Button>
        )}

        <Dialog maxWidth="md" fullWidth open={open} onClose={handleClose}>
          <DialogTitle>Re-evaluate Evaluation</DialogTitle>
          <DialogContent sx={{ my: 4 }}>
            <Formik
              initialValues={{
                reason: "",
              }}
              onSubmit={(values) => {
                console.log(values);
                handleSubmit(values);
              }}>
              {() => {
                return (
                  <Form>
                    <Stack sx={{ mt: 2 }}>
                      <TextfieldWrapper
                        id="reason"
                        name="reason"
                        label="Reason"
                        multiline
                        rows={4}
                        fullWidth
                        required
                      />

                      <Stack
                        sx={{ my: 2 }}
                        spacing={2}
                        direction="row"
                        justifyContent="flex-end">
                        <Button
                          onClick={() => {
                            handleClose();
                          }}>
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          color="secondary"
                          type="submit">
                          Submit
                        </Button>
                      </Stack>
                    </Stack>
                  </Form>
                );
              }}
            </Formik>
          </DialogContent>
        </Dialog>
      </div>
    </div>
  );
};

export default withAuth(ReinstateEvaluation);
