import {
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Box,
} from "@mui/material";

import React, { useEffect } from "react";

import MainAppContainer from "../../Components/Containers/MainAppContainer";
import withAuth from "../../Hoc/withAuth";
import { useDispatch, useSelector } from "react-redux";
import { GetAllUsers } from "../../Redux/Ducks/AdminUserManagement";

import { RESET_ADMIN_USER_STATE } from "../../Redux/ActionNames/AllActions";
import { getCompanies } from "../../Redux/Ducks/AdminCompniesDuck";
import AlertPopup from "../../Components/Bits/AlertPopup";
import { useNavigate } from "react-router-dom";
import DeleteUser from "./components/DeleteUserDialog";
import RestoreUser from "./components/RestoreUserDialog";

const UserManagement = ({ user, companyDetails }) => {
  // if(user.userType !== "admin") {
  //   return (
  //     <>
  //       <Typography align="center" variant="h2">
  //         You are not authorized to view this page
  //       </Typography>
  //     </>
  //   );
  // }

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCompanies());
  }, [dispatch]);

  useEffect(() => {
    dispatch(GetAllUsers());
  }, [dispatch]);

  const AdminUsers = useSelector((state) => state.AdminUsers);

  const { allUsers, deleteUser, restoreUser } = AdminUsers;

  const [search, setSearch] = React.useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    dispatch({ type: RESET_ADMIN_USER_STATE });
  }, [dispatch]);

  let users = [];

  if (user.userType === "admin") {
    users = allUsers;
  } else if (user.userType === "super_admin") {
    allUsers.filter(
      (user) =>
        user?.companies_users[0]?.company_id === companyDetails.companyId &&
        users.push(user),
    );
  } else {
    return (
      <>
        <Typography align="center" variant="h2">
          You are not authorized to view this page
        </Typography>
      </>
    );
  }

  const searchedUser = users?.filter((user) => {
    const full_name = user?.name + " " + user?.surname;
    return full_name.toLowerCase()?.includes(search.toLowerCase());
  });

  // order searchedUser by name
  searchedUser.sort((a, b) => {
    const full_name = a.name + " " + a.surname;
    const full_name2 = b.name + " " + b.surname;
    if (full_name < full_name2) {
      return -1;
    }
    if (full_name > full_name2) {
      return 1;
    }
    return 0;
  });

  // console.log("USERSDEL", users);

  return (
    <MainAppContainer>
      <Grid container>
        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
          <Typography sx={{ mb: 4 }} variant="h4" align="center">
            User Management
          </Typography>
          <Stack
            sx={{ mb: 4 }}
            spacing="4"
            direction="row"
            justifyContent="space-evenly"
          >
            <Button
              variant="contained"
              onClick={() => {
                navigate("/AddNewUser");
              }}
            >
              Add User
            </Button>
          </Stack>

          <Stack mb={3}>
            <TextField
              name="search"
              label="Search"
              variant="outlined"
              onChange={handleSearch}
              fullWidth
            />
          </Stack>

          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="left" style={{ width: "20%" }}>
                    User
                  </TableCell>
                  <TableCell align="right" style={{ width: "25%" }}>
                    Email
                  </TableCell>
                  <TableCell align="right" style={{ width: "20%" }}>
                    Company
                  </TableCell>
                  <TableCell align="right" style={{ width: "15%" }}>
                    User Type
                  </TableCell>
                  <TableCell align="right" style={{ width: "20%" }}>
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users &&
                  searchedUser?.map((U) => (
                    <TableRow
                      key={U?.id}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        opacity: U?.deletedAt ? 0.5 : 1,
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {U.name + " " + U?.surname}
                      </TableCell>
                      <TableCell align="right">{U?.email}</TableCell>
                      <TableCell align="right">
                        {" "}
                        {U?.companies_users &&
                          U?.companies_users[0]?.company?.name}
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <Chip
                          onClick={() => navigate(`/admin/users/${U?.id}`)}
                          variant="outlined"
                          color={
                            U?.deletedAt !== null
                              ? "error"
                              : U?.user_type?.type === "admin"
                              ? "primary"
                              : U?.user_type?.type === "super"
                              ? "error"
                              : U?.user_type?.type === "super_admin"
                              ? "warning"
                              : "secondary"
                          }
                          label={U?.deletedAt ? "Inactive" : U?.user_type?.type}
                        />
                      </TableCell>
                      <TableCell align="right">
                        {" "}
                        <Box display="flex" justifyContent="right">
                          {U?.deletedAt ? (
                            <RestoreUser id={U.id} />
                          ) : (
                            <>
                              <Button
                                onClick={() => {
                                  navigate(`/EditUser/${U?.id}`);
                                }}
                              >
                                Edit
                              </Button>
                              <DeleteUser name={U.name} id={U.id} />
                            </>
                          )}
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          {/* 
          <Stack>
            <Grid container spacing={1}>
              {users &&
                searchedUser?.map((U) => {
                  return (
                    <Grid item key={U.id} xl={6} lg={6} md={6} sm={12} xs={12}>
                      <UserCard key={U.id} U={U} deleteUser={deleteUser} />
                    </Grid>
                  );
                })}
            </Grid>
          </Stack> */}
        </Grid>
        <AlertPopup
          severity="success"
          message="User Deleted Successfully"
          open={deleteUser}
        />
        <AlertPopup
          severity="success"
          message="User Restored Successfully"
          open={restoreUser}
        />
      </Grid>
    </MainAppContainer>
  );
};

export default withAuth(UserManagement);
